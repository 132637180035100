import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const style = (theme: AppTheme) =>
  StyleSheet.create({
    cannotCancel: {
      color: theme.colors.primary,
      textAlign: 'center',
      marginBottom: theme.spacing.s4,
      ...setFontWeight('700'),
    },
    pauseOrCancel: {
      textAlign: 'center',
      lineHeight: theme.fonts.headlineSmall.lineHeight,
      color: theme.colors.primary,
      paddingBottom: theme.spacing.s4,
    },
  });

export const StyledContainer = tw.div`
  p-6 pt-0
  max-md:pb-20
`;

export const StyledButtonContainer = tw.div`
  absolute w-full p-6 bottom-[var(--footer-size)] left-0
  md:relative md:bottom-0 md:mt-4
`;

export const StyledPauseOrCancelContainer = tw.div`
  px-6 py-10 mb-2 flex flex-col
`;
