import tw from 'tailwind-styled-components';

export const StyledContainer = tw.div`
  flex flex-col gap-4 pb-12
  md:pb-0
`;

export const StyledButtonContainer = tw.div`
  absolute w-full p-6 bottom-[var(--footer-size)] left-0
  md:relative md:bottom-0 md:pt-0
`;
