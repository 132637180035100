import { useContext, useEffect, useMemo, useState } from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';
import { useCancellationController } from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { Button, ScrollView, Text } from '@lawnstarter/ls-react-common/atoms';
import { ModalContext } from '@lawnstarter/ls-react-common/contexts';
import { CancellationDeflection, CancellationReason } from '@lawnstarter/ls-react-common/enums';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ActionAlternativeOption } from '@lawnstarter/ls-react-common/molecules';
import { CancellationReasonForm, ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import {
  CancellationDeflectionChangePro,
  CancellationDeflectionChangeSchedule,
  CancellationDeflectionMoving,
  CancellationDeflectionPause,
  CancellationDeflectionServiceDisputable,
  CancellationDeflectionServiceFixScheduled,
  CancellationDeflectionServicePendingFix,
  CancellationDeflectionSkip,
  CancellationDeflectionSupport,
  DetailsHeader,
} from '@src/components';
import { useAppContext } from '@src/contexts';
import { useAppDownloadModal, useRouteNavigation } from '@src/hooks';
import { trackingService } from '@src/services';

import {
  style,
  StyledButtonContainer,
  StyledContainer,
  StyledPauseOrCancelContainer,
} from './styles';

function CancelServiceScreenOld() {
  const { setTitle } = useAppContext();
  const modal = useModal(ModalTemplate.INFO);
  const modalContext = useContext(ModalContext);
  const { navigate } = useRouteNavigation();
  const { propertyId, scheduleId } = useParams();
  const [showPauseOrCancel, setShowPauseOrCancel] = useState(true);

  const { theme, schedule, property, canChangePro, isRootService, isLawnMowing, supportPath } =
    useCancellationController({
      scheduleId: scheduleId as string,
      propertyId: propertyId as string,
    });

  const styles = useMemo(() => style(theme), [theme]);

  const canCancelMowing = useMemo(() => property?.can_cancel_mowing_services ?? false, [property]);

  const routeParams = useMemo(
    () =>
      property && schedule
        ? {
            propertyId: property.id,
            scheduleId: schedule.id,
          }
        : undefined,
    [property, schedule],
  );

  const generatedSupportPath = useMemo(
    () => generatePath(supportPath, { propertyId: `${propertyId}`, scheduleId: `${scheduleId}` }),
    [supportPath, propertyId, scheduleId],
  );

  useEffect(() => {
    if (!isLawnMowing) {
      setShowPauseOrCancel(false);
    }
  }, [isLawnMowing]);

  useEffect(() => {
    const title = showPauseOrCancel ? t('pauseOrCancel.title') : t('cancelService.title');
    setTitle(title);
  }, [showPauseOrCancel, setTitle]);

  const renderBlockCancellationModal = () =>
    modal.show({
      content: (
        <>
          <Text variant="headlineSmall" style={styles.cannotCancel}>
            {t('cancelService.blockCancelation.cannotCancel')}
          </Text>
          <Text variant="bodyMedium">
            {t('cancelService.blockCancelation.please')}
            <div className="inline" style={{ color: theme.colors.blue.primary }}>
              <Link
                to={generatedSupportPath}
                onClick={() => {
                  modalContext.hide();
                }}
              >
                {` ${t('cancelService.blockCancelation.linkText')} `}
              </Link>
            </div>
            {t('cancelService.blockCancelation.help')}
          </Text>
        </>
      ),
    });

  const renderPauseOrCancel = () => (
    <>
      <DetailsHeader showBackButton />
      <StyledPauseOrCancelContainer>
        <Text variant="headlineSmall" style={styles.pauseOrCancel}>
          {t('pauseOrCancel.thanks')}
        </Text>
        <Text
          style={{ textAlign: 'center', lineHeight: theme.fonts.bodyMedium.lineHeight }}
          variant="bodyMedium"
        >
          {t('pauseOrCancel.message')}
        </Text>
      </StyledPauseOrCancelContainer>
      <StyledButtonContainer>
        <Button
          mode="contained"
          style={{ marginBottom: theme.spacing.s3 }}
          trackID="cancel_service_screen-pause_service"
          onPress={() => {
            navigate(WebRoutes.pauseService, {
              params: routeParams,
            });
          }}
        >
          {t('pauseOrCancel.pauseUntilSpring')}
        </Button>
        <Button
          trackID="cancel_service_screen-cancel_service"
          mode="contained-tonal"
          onPress={() => {
            if (canCancelMowing) {
              setShowPauseOrCancel(false);
              return;
            }

            renderBlockCancellationModal();
          }}
        >
          {t('pauseOrCancel.cancelService').toUpperCase()}
        </Button>
      </StyledButtonContainer>
    </>
  );

  if (showPauseOrCancel) {
    return renderPauseOrCancel();
  }

  return (
    <>
      <DetailsHeader showBackButton />
      <StyledContainer>
        <ActionAlternativeOption
          showDivider
          title={t('cancelService.moving.heading')}
          description={t('cancelService.moving.content')}
          target={{
            name: t('contactSupport.title').toUpperCase(),
            onPress: () => navigate(supportPath as WebRoutes, { params: routeParams }),
          }}
        />
        {canChangePro && (
          <ActionAlternativeOption
            showDivider
            title={t('cancelService.changePro.heading')}
            description={t('cancelService.changePro.content')}
            target={{
              name: t('changeProvider').toUpperCase(),
              onPress: () => navigate(WebRoutes.changeMyPro, { params: routeParams }),
            }}
          />
        )}
        <ActionAlternativeOption
          title={t('cancelService.stillCancel.heading')}
          description={t('cancelService.stillCancel.content')}
        />
        {isRootService && <Text>{t('cancelService.cancelationNote')}</Text>}
      </StyledContainer>
      <StyledButtonContainer className="flex">
        <div style={{ flex: 1, paddingRight: theme.spacing.s1 }}>
          <Button
            mode="contained-tonal"
            trackID="cancel_service_screen-close"
            onPress={() =>
              navigate(WebRoutes.serviceDetail, {
                params: routeParams,
              })
            }
          >
            {t('goBack')}
          </Button>
        </div>
        <div style={{ flex: 2, paddingLeft: theme.spacing.s1 }}>
          <Button
            mode="contained"
            trackID="cancel_service_screen-continue"
            onPress={() => navigate(WebRoutes.confirmCancel, { params: routeParams })}
          >
            {t('cancelService.submit').toUpperCase()}
          </Button>
        </div>
      </StyledButtonContainer>
    </>
  );
}

export function CancelServiceScreen() {
  const propertyId = Number(useParams().propertyId);
  const scheduleId = Number(useParams().scheduleId);
  const { setTitle, setSubTitle } = useAppContext();

  const { navigate } = useRouteNavigation();
  const { showAppDownloadModal } = useAppDownloadModal();

  function onConfirmCancellation() {
    navigate(WebRoutes.confirmCancel, { params: routeParams });
  }

  function onDeflectionChangePro() {
    navigate(WebRoutes.changeMyPro, { params: routeParams });
  }

  function onDeflectionChangeSchedule() {
    navigate(WebRoutes.changeSchedule, { params: routeParams });
  }

  function onDeflectionPause() {
    navigate(WebRoutes.pauseService, { params: routeParams });
  }

  function onDeflectionSkip() {
    navigate(WebRoutes.skipService, { params: routeParams });
  }

  function onDeflectionSupport() {
    navigate(WebRoutes.supportFromCancel, { params: routeParams });
  }

  async function onDeflectionServiceDisputable() {
    showAppDownloadModal();
  }

  function onDeflectionServicePendingFix() {
    navigate(WebRoutes.services, { params: routeParams });
  }

  function onDeflectionServiceFixScheduled() {
    navigate(WebRoutes.serviceDetail, {
      params: { propertyId: routeParams.propertyId, scheduleId: `${scheduleServiceFix?.id}` },
    });
  }

  const {
    contractorName,
    deflection,
    isCustomQualityDeflectionFlow,
    isCustomSchedulingDeflectionFlow,
    isFlagImproveCancellationGenericDeflections,
    onNextStep,
    onReasonChange,
    onReasonExplanationChange,
    reason,
    reasonExplanation,
    scheduleServiceFix,
    serviceName,
  } = useCancellationController({
    onConfirmCancellation,
    propertyId,
    scheduleId,
    trackingService,
  });

  const routeParams = useMemo(
    () => ({
      propertyId,
      scheduleId,
      cancellationReason: `${reason}`,
    }),
    [propertyId, scheduleId, reason],
  );

  if (!isFlagImproveCancellationGenericDeflections) {
    return <CancelServiceScreenOld />;
  }

  setTitle(t('cancelService.title'));
  serviceName && setSubTitle(serviceName);

  if (deflection === CancellationDeflection.ChangePro) {
    return (
      <>
        <DetailsHeader showBackButton />
        <CancellationDeflectionChangePro
          onPrimaryPress={onDeflectionChangePro}
          onSecondaryPress={onNextStep}
          reason={isCustomSchedulingDeflectionFlow ? reason : null}
        />
      </>
    );
  }

  if (deflection === CancellationDeflection.ChangeSchedule) {
    return (
      <>
        <DetailsHeader showBackButton />
        <CancellationDeflectionChangeSchedule
          onPrimaryPress={onDeflectionChangeSchedule}
          onSecondaryPress={onNextStep}
        />
      </>
    );
  }

  if (deflection === CancellationDeflection.ContactSupport) {
    return (
      <>
        <DetailsHeader showBackButton />
        <CancellationDeflectionSupport
          onPrimaryPress={onDeflectionSupport}
          onSecondaryPress={onNextStep}
        />
      </>
    );
  }

  if (deflection === CancellationDeflection.Moving) {
    return (
      <>
        <DetailsHeader showBackButton />
        <CancellationDeflectionMoving onPrimaryPress={onNextStep} />
      </>
    );
  }

  if (deflection === CancellationDeflection.Pause) {
    return (
      <>
        <DetailsHeader showBackButton />
        <CancellationDeflectionPause
          contractorName={contractorName}
          onPrimaryPress={onDeflectionPause}
          onSecondaryPress={onNextStep}
        />
      </>
    );
  }

  if (deflection === CancellationDeflection.Skip) {
    return (
      <>
        <DetailsHeader showBackButton />
        <CancellationDeflectionSkip
          onPrimaryPress={onDeflectionSkip}
          onSecondaryPress={onNextStep}
        />
      </>
    );
  }

  if (deflection === CancellationDeflection.ServiceDisputable) {
    return (
      <>
        <DetailsHeader showBackButton />
        <CancellationDeflectionServiceDisputable
          onPrimaryPress={onDeflectionServiceDisputable}
          onSecondaryPress={onNextStep}
        />
      </>
    );
  }

  if (deflection === CancellationDeflection.ServicePendingFix) {
    return (
      <>
        <DetailsHeader showBackButton />
        <CancellationDeflectionServicePendingFix
          onPrimaryPress={onDeflectionServicePendingFix}
          onSecondaryPress={onNextStep}
        />
      </>
    );
  }

  if (deflection === CancellationDeflection.ServiceFixScheduled) {
    return (
      <>
        <DetailsHeader showBackButton />
        <CancellationDeflectionServiceFixScheduled
          onPrimaryPress={onDeflectionServiceFixScheduled}
          onSecondaryPress={onNextStep}
        />
      </>
    );
  }

  type CancellationReasonFormProps = Parameters<typeof CancellationReasonForm>[0];

  const props: CancellationReasonFormProps = isCustomSchedulingDeflectionFlow
    ? {
        onChange: onReasonExplanationChange,
        options: [
          CancellationReason.SchedulingNoProEver,
          CancellationReason.SchedulingDoesntWork,
          CancellationReason.SchedulingNoProLastTime,
        ].map((value) => ({
          label: t(`cancelService.reason.${value}`),
          value,
        })),
        title: t('cancelService.whatIssuesHaveYouHadWithYourSchedule'),
        selected: reasonExplanation ?? undefined,
      }
    : isCustomQualityDeflectionFlow
      ? {
          onChange: onReasonExplanationChange,
          options: [
            CancellationReason.QualityNotGoodEnough,
            CancellationReason.QualityServiceNotAsExpected,
            CancellationReason.QualityProIsPoaching,
            CancellationReason.QualityAnotherIssue,
          ].map((value) => ({
            label: t(`cancelService.reason.${value}`),
            value,
          })),
          title: t('cancelService.whatIssuesHaveYouHadWithYourServiceOrPro'),
          selected: reasonExplanation ?? undefined,
        }
      : {
          onChange: onReasonChange,
          options: [
            CancellationReason.Moving,
            CancellationReason.Temporary,
            CancellationReason.Scheduling,
            CancellationReason.Quality,
            CancellationReason.Charges,
            CancellationReason.Pricing,
            CancellationReason.Support,
          ].map((value) => ({
            label: t(`cancelService.reason.${value}`),
            value,
          })),
          title: t('cancelService.whyAreYouCancelling'),
          selected: reason ?? undefined,
        };

  return (
    <>
      <DetailsHeader showBackButton />
      {
        <ScrollView>
          <CancellationReasonForm {...props} />
        </ScrollView>
      }
    </>
  );
}
