import { useMemo } from 'react';
import { View } from 'react-native';
import { Divider } from 'react-native-paper';
import { useParams } from 'react-router-dom';
import tw from 'tailwind-styled-components';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import {
  auth_signedUserSelector,
  orderServices_orderPricedLtrsService,
  properties_currentPropertySelector,
} from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, ScrollView, Text } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { AboutLawnTreatment } from '@src/components';
import { LawnTreatmentConfirmation } from '@src/components/molecules/LawnTreatmentConfirmation';
import { useDispatch, useRouteNavigation, useSelector } from '@src/hooks';

import { styles } from './styles';

const StyledOrderLawnTreatmentContainer = tw.section`
flex flex-col
items-start
md:flex-row-reverse md:gap-6
`;

const DesktopOnlyWrapper = tw.div`
  hidden md:flex flex-col w-full
`;

export const MobileOnlyWrapper = tw.div`
  flex flex-col md:hidden
`;

const StyledOrderLawnTreatmentInfoContainer = tw.div`
mt-5 w-full mx-auto 
md:w-8/12
`;

export const ConfirmLawnTreatmentScreen = () => {
  const property = useSelector(properties_currentPropertySelector);

  const { email: customerEmail } = useSelector(auth_signedUserSelector);
  const { navigate } = useRouteNavigation();
  const { propertyId, servicePromoId } = useParams();
  const isUpdating = useSelector(({ orderServices }) => orderServices.loadingStatus.isUpdating);
  const lawnTreatmentInfo = useSelector(
    ({ properties }) =>
      properties.propertiesById[propertyId as string]?.priced?.services?.fertilization,
  );
  const modal = useModal(ModalTemplate.ACTION_CONFIRMATION);
  const dispatch = useDispatch();
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  const onSubmit = async () => {
    const { success, error } = await dispatch(
      orderServices_orderPricedLtrsService({
        property_id: propertyId,
        service_promo_id: servicePromoId,
        service_id: lawnTreatmentInfo?.service.id,
        price_id: lawnTreatmentInfo?.options[0].price_id,
      }),
    );

    if (success) {
      const message = t('confirmationServiceOrder.message', {
        customerEmail,
        serviceName: lawnTreatmentInfo?.service.name,
      });
      modal.show({
        icon: 'checkbox-marked-circle-plus-outline',
        action: t('confirmationServiceOrder.title'),
        messages: [message],
        buttonText: t('goToMyServices'),
        onDismiss: () => navigate(WebRoutes.services, { options: { replace: true } }),
      });
    } else {
      modal.show({
        icon: 'block-helper',
        action: t('somethingWentWrong'),
        messages: [error?.error?.message ?? t('apiGenericError')],
        buttonText: t('tryAgain'),
      });
    }
  };

  const renderAddress = () => {
    return (
      <View>
        <Text variant="bodyLarge" style={style.sectionTitle}>
          {t('ltrs.scheduleLtrs.addressSectionTitle')}
        </Text>
        <Text>
          {property?.street}, {property?.city} {property?.state}
        </Text>
        <Text>{property?.zip}</Text>
        <Divider style={style.divider} />
      </View>
    );
  };

  const renderService = () => {
    return (
      <View>
        <Text variant="bodyLarge" style={style.sectionTitle}>
          {t('ltrs.scheduleLtrs.serviceSectionTitle')}
        </Text>
        <View style={style.serviceSection}>
          <View>
            <Text>{t('ltrs.scheduleLtrs.lawnTreatmentServiceName')}</Text>
          </View>
          <View>
            <Text style={style.servicePrice}>
              {t('ltrs.scheduleLtrs.servicePrice', {
                price: lawnTreatmentInfo?.options[0].price ?? '',
              })}
            </Text>
            <Text>{t('ltrs.scheduleLtrs.serviceFrequency')}</Text>
          </View>
        </View>
        <Divider style={style.divider} />
      </View>
    );
  };

  const renderDueSection = () => {
    return (
      <View style={style.dueSection}>
        <View style={style.dueDay}>
          <Text variant="bodyLarge" style={style.sectionTitle}>
            {t('ltrs.scheduleLtrs.dueSectionTitle')}
          </Text>
          <Text variant="bodyLarge" style={style.sectionTitle}>
            {t('ltrs.scheduleLtrs.dueAmount')}
          </Text>
        </View>
        <Text style={style.dueDetails}>{t('ltrs.scheduleLtrs.dueDetails')}</Text>

        <Text>{t('ltrs.scheduleLtrs.firstServiceDetails')}</Text>
      </View>
    );
  };

  return (
    <>
      <MobileOnlyWrapper>
        <View style={style.screenContainer}>
          <ScrollView>
            <View style={style.contentContainer}>
              {renderAddress()}
              {renderService()}
              {renderDueSection()}
            </View>
            <Button
              trackID="order_lawn_treatment_footer-cta"
              onPress={onSubmit}
              loading={isUpdating}
              buttonColor={theme.colors.primary}
              textColor={theme.colors.onPrimary}
              style={{
                marginTop: theme.spacing.s8,
              }}
            >
              {t('ltrs.scheduleLtrs.orderNowButton')}
            </Button>
          </ScrollView>
        </View>
      </MobileOnlyWrapper>

      <DesktopOnlyWrapper>
        <StyledOrderLawnTreatmentContainer>
          <AboutLawnTreatment
            onPress={onSubmit}
            price={lawnTreatmentInfo?.options[0].price ?? ''}
            isLoading={isUpdating}
            shouldOrder={true}
          />
          <StyledOrderLawnTreatmentInfoContainer>
            <LawnTreatmentConfirmation price={lawnTreatmentInfo?.options[0].price ?? ''} />
          </StyledOrderLawnTreatmentInfoContainer>
        </StyledOrderLawnTreatmentContainer>
      </DesktopOnlyWrapper>
    </>
  );
};
