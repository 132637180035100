import { Navigate, useLocation } from 'react-router-dom';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';

import type { ReactNode } from 'react';

const EMPTY_STACK_KEY = 'default';

export function OnlyProgrammaticAccess({ component }: { component: ReactNode }) {
  const { key } = useLocation();

  const isStackEmpty = () => key === EMPTY_STACK_KEY;

  return isStackEmpty() ? <Navigate to={WebRoutes.services} /> : component;
}
