import { t } from '@lawnstarter/customer-modules/services';
import { ExtendedYardLocations } from '@lawnstarter/ls-constants';
import { ImageUploadInput, TextArea, TextInput } from '@lawnstarter/ls-react-common/atoms';
import { InputLayout } from '@lawnstarter/ls-react-common/enums';
import { CheckboxOptionsForm } from '@lawnstarter/ls-react-common/molecules';

import { GenericQuoteFormProps } from './types';

export function GenericQuoteForm({ control, isUpdating }: GenericQuoteFormProps) {
  return (
    <div className="flex flex-col gap-6">
      <TextArea
        name="property_items"
        control={control}
        placeholder={t('forms.enterDetailsHere')}
        label={t('genericManualQuoteForm.whichItemsToBeServiced')}
        rules={{ required: true }}
        disabled={isUpdating}
      />

      <TextArea
        name="desired_work"
        control={control}
        placeholder={t('forms.enterDetailsHere')}
        label={t('genericManualQuoteForm.whatToBeDone')}
        rules={{ required: true }}
        disabled={isUpdating}
      />

      <CheckboxOptionsForm
        options={ExtendedYardLocations}
        control={control}
        title={t('forms.yardLocations')}
        name="locations"
        rules={{ required: true }}
      />

      <TextInput
        name="budget"
        control={control}
        rules={{ required: true }}
        disabled={isUpdating}
        inputLayout={InputLayout.currency}
        placeholder={t('forms.placeholders.currency')}
        label={t('genericManualQuoteForm.budgetForQuote')}
        keyboardType="numbers-and-punctuation"
      />

      <ImageUploadInput
        name="pendingImages"
        control={control}
        isUploading={isUpdating}
        singleUpload={false}
        label={t('forms.uploadPhotos')}
        uploadText={t('forms.upload.uploading')}
        tooLargeFilesText={t('forms.upload.fileTooLarge')}
        addButton={{ text: t('forms.upload.placeholder') }}
      />
    </div>
  );
}
