import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useConfirmCancellationController } from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { Button, ScrollView } from '@lawnstarter/ls-react-common/atoms';
import { CancellationReason } from '@lawnstarter/ls-react-common/enums';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ConfirmCancellationForm, ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { DetailsHeader } from '@src/components';
import { useAppContext } from '@src/contexts';
import { useRouteNavigation } from '@src/hooks';
import { NewPropertyFormModal } from '@src/modals';

import { StyledButtonContainer, StyledContainer } from './styles';

export function ConfirmCancelScreen() {
  const confirmationModal = useModal(ModalTemplate.CONFIRMATION);
  const { navigate } = useRouteNavigation();
  const { propertyId, scheduleId, cancellationReason } = useParams();
  const { setSubTitle } = useAppContext();

  const newPropertyModal = useModal(NewPropertyFormModal);

  const {
    cancellationFormFields,
    control,
    handleSubmit,
    hideReasonSelector,
    isLoading,
    isFlagImproveCancellationGenericDeflections,
    serviceName,
    withIssueNotes,
  } = useConfirmCancellationController({
    scheduleId: scheduleId as string,
    propertyId: propertyId as string,
    confirmationModal,
    reason: cancellationReason as CancellationReason,
    onSuccess: () => {
      navigate(WebRoutes.services, {
        options: { replace: true },
      });
    },
    onAddNewProperty: () => {
      confirmationModal.hide();
      setTimeout(() => newPropertyModal.show(), 100);
    },
  });

  useEffect(() => {
    if (isFlagImproveCancellationGenericDeflections && serviceName) {
      setSubTitle(serviceName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFlagImproveCancellationGenericDeflections, serviceName]);

  return (
    <>
      <DetailsHeader showBackButton />
      <ScrollView>
        <StyledContainer>
          <ConfirmCancellationForm
            control={control}
            defaultReason={cancellationReason}
            fields={cancellationFormFields}
            hideReasonSelector={hideReasonSelector}
            isUpdating={isLoading}
            origin="cancel_service_screen"
            withIssueNotes={withIssueNotes}
          />
        </StyledContainer>
      </ScrollView>
      <StyledButtonContainer>
        <Button
          trackID="confirm_cancellation_screen-confirm_cancellation"
          onPress={handleSubmit}
          mode="contained"
          loading={isLoading}
          disabled={isLoading}
        >
          {t('cancelService.confirmCancelation').toUpperCase()}
        </Button>
      </StyledButtonContainer>
    </>
  );
}
