export enum BadReviewAction {
  Disputed = 'disputed',
  OfferCycleChange = 'offer_cycle_change',
  PropertyDamage = 'property_damage',
}

export enum IssueOptionKey {
  Quality = 'quality',
  PartialComplete = 'partial_complete',
  NoWorkDone = 'no_work_done',
  PropertyDamage = 'property_damage',
}
