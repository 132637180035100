import { useContext, useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { FeatureFlags, WebRoutes } from '@lawnstarter/customer-modules/enums';
import { useFeatureFlag } from '@lawnstarter/customer-modules/hooks';
import { useGetSurveysQuery } from '@lawnstarter/customer-modules/stores/modules';
import { ModalContext } from '@lawnstarter/ls-react-common/contexts';
import { useModal } from '@lawnstarter/ls-react-common/hooks';

import { SessionStorageKeys } from '@src/enums';
import { SatisfactionSurveyModal } from '@src/modals/SatisfactionSurveyModal';

import type { SkippableHookProps } from '@src/types';

function wasSurveyShown() {
  return sessionStorage.getItem(SessionStorageKeys.InAppSurveyModalTriggered) === '1';
}

function setSurveyShown(value: boolean) {
  sessionStorage.setItem(SessionStorageKeys.InAppSurveyModalTriggered, value ? '1' : '0');
}

export function useAskSatisfactionSurvey({ skip = false }: SkippableHookProps) {
  const modalContext = useContext(ModalContext);
  const surveyModal = useModal(SatisfactionSurveyModal);
  const isBrowseRoute = useMatch(WebRoutes.browse);
  const isFlagEnabled = useFeatureFlag(FeatureFlags.ALLOW_CUSTOMER_SATISFACTION_SURVEY);
  const [hasAskedSurvey, setAskedSurvey] = useState(false);
  const [hasFinished, setFinished] = useState(false);
  const { data: survey, isFetching } = useGetSurveysQuery(undefined, {
    skip: hasFinished || !isFlagEnabled,
  });

  useEffect(() => {
    const shouldSkip =
      skip || // Explicitly received to skip the hook.
      isFetching || // We are waiting API data to load.
      hasFinished; // This hook finished processing.

    if (shouldSkip) {
      // TODO: Debug to be removed PE-43786
      // eslint-disable-next-line no-console
      console.log('shouldSkip', {
        skip,
        isFetching,
        hasFinished,
      });

      return;
    }

    const wasShown = wasSurveyShown();
    const shouldFinish =
      modalContext.visible || // A modal is already visible.
      !isBrowseRoute || // Shouldn't run outside the main screen.
      !isFlagEnabled || // Satisfaction survey feature flag is not enabled.
      !survey || // No survey received from the API to present to this user.
      wasShown; // User has already been asked for survey in this session.

    if (shouldFinish) {
      setFinished(true);

      // TODO: Debug to be removed PE-43786
      // eslint-disable-next-line no-console
      console.log('shouldFinish', {
        modalContext: modalContext.visible,
        isBrowseRoute,
        isFlagEnabled,
        survey,
        wasShown,
      });

      return;
    }

    setSurveyShown(true);
    setAskedSurvey(true);

    surveyModal.show({
      animationType: 'fade',
      closeable: true,
      backdropCloseable: false,
      survey,
    });
  }, [
    hasFinished,
    isBrowseRoute,
    isFetching,
    isFlagEnabled,
    modalContext.visible,
    skip,
    survey,
    surveyModal,
  ]);

  return {
    hasAskedSurvey,
    isLoading: !hasFinished,
  };
}
