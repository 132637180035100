import BottomSheet from '@devvie/bottom-sheet';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { View } from 'react-native';
import { useParams } from 'react-router-dom';
import tw from 'tailwind-styled-components';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { eventEmitter, getBrand, getHelpDeskActions } from '@lawnstarter/customer-modules/helpers';
import { t } from '@lawnstarter/customer-modules/services';
import {
  properties_currentPropertySelector,
  useGetMarketFlagQuery,
} from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import { ActionList } from '@lawnstarter/ls-react-common/organisms';

import { useAppDownloadModal, useRouteNavigation, useScrollBlock, useSelector } from '@src/hooks';
import { MobileHeaderEvents } from '@src/layout/Header/MobileHeader/MobileHeaderEvents';
import { errorService } from '@src/services';

import { styles } from './styles';

import type { BottomSheetMethods } from '@devvie/bottom-sheet';
import type { JobInformation } from '@lawnstarter/ls-react-common/types';

export function HelpDeskBottomSheet({
  propertyId,
  jobInformation,
  defaultScheduleEventId,
}: {
  propertyId: number;
  jobInformation?: JobInformation;
  defaultScheduleEventId?: number;
}) {
  const brand = getBrand();
  const params = useParams();
  const theme = useAppTheme();
  const { navigate } = useRouteNavigation();
  const [blockScroll, allowScroll] = useScrollBlock();
  const [sheetVisibility, updateSheetVisibility] = useState(false);
  const sheetRef = useRef<BottomSheetMethods>(null);
  const { showAppDownloadModal } = useAppDownloadModal();
  const style = useMemo(() => styles(theme), [theme]);

  const property = useSelector(properties_currentPropertySelector);
  const schedule = useSelector((state) =>
    params.scheduleId ? state.schedules.schedulesById[params.scheduleId] : null,
  );

  const { data: newHelpFlowFlag } = useGetMarketFlagQuery({
    marketId: property?.market_id,
    flagName: 'zendesk.customer.messaging',
  });

  const supportPath = useMemo(() => {
    return newHelpFlowFlag?.is_enabled
      ? WebRoutes.support
      : brand.slug === 'lawnstarter'
        ? WebRoutes.supportLawnstarter
        : WebRoutes.supportLawnlove;
  }, [newHelpFlowFlag, brand]);

  // Listen to open requests
  useEffect(() => {
    const helpDeskSelectPress = () => {
      sheetRef.current?.open();
      blockScroll();
    };

    const source = eventEmitter.on(
      MobileHeaderEvents.onHelpDeskSelectPressEvent,
      helpDeskSelectPress,
    );

    return () => {
      source.removeListener(MobileHeaderEvents.onHelpDeskSelectPressEvent, helpDeskSelectPress);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeBottomSheet = useCallback(() => {
    sheetRef.current?.close();
    allowScroll();
  }, [allowScroll]);

  const helpDeskActions = useMemo(
    () =>
      getHelpDeskActions({
        schedule,
        jobInformation,
        propertyId,
        defaultScheduleEventId,
        errorService,
      }),
    [schedule, jobInformation, propertyId, defaultScheduleEventId],
  );

  const handleIconNames = (name: string) => {
    if (name === 'contact-support') {
      return 'phone-message';
    } else if (name === 'support-action') {
      return 'comment-question';
    } else {
      return 'help-box';
    }
  };

  return (
    <StyledHelpDeskBottomSheetWrapper $sheetVisibility={sheetVisibility}>
      <BottomSheet
        height={'60%'}
        ref={sheetRef}
        style={{ zIndex: 20, backgroundColor: theme.colors.white.primary }}
        onAnimate={(animated: number) => {
          // Avoid weird visual behaviors
          if (animated === 0 && sheetVisibility) {
            updateSheetVisibility(false);
          } else if (animated > 0 && !sheetVisibility) {
            updateSheetVisibility(true);
          }
        }}
        onClose={() => {
          allowScroll();
        }}
        android_closeOnBackPress={false}
      >
        <View style={style.closeButtonContainer}>
          <Button
            labelStyle={style.closeButtonLabel}
            textColor={theme.colors.gray.tertiary}
            trackID="close-help-desk"
            onPress={closeBottomSheet}
            useLowercase
          >
            <Icon name="close" />
          </Button>
        </View>

        <View style={{ maxHeight: '95%', padding: theme.spacing.s5 }}>
          <Text variant="titleLarge" style={style.headerTitle}>
            {t('helpDesk')}
          </Text>
          <ActionList
            items={helpDeskActions.map(({ title, icon, href, parameters }) => {
              return {
                label: title,
                trackID: `${_.snakeCase(title)}-help_desk_bottom_sheet`,
                icon: <Icon size={theme.sizing.s7} name={handleIconNames(icon)} />,
                action: () => {
                  if ([WebRoutes.support, WebRoutes.changeMyPro].includes(href as WebRoutes)) {
                    closeBottomSheet();
                    return navigate(href === WebRoutes.support ? supportPath : href, {
                      params: parameters,
                    });
                  }

                  showAppDownloadModal();
                },
              };
            })}
          />
        </View>
      </BottomSheet>
    </StyledHelpDeskBottomSheetWrapper>
  );
}

const StyledHelpDeskBottomSheetWrapper = tw.div<{ $sheetVisibility: boolean }>`
  ${(params) => (params.$sheetVisibility ? 'z-10' : '-z-10')}
  fixed top-0 right-0 left-0 h-full
`;
